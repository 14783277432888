<template>
	<div class="i-surveys">
		<transition name="van-fade" mode="out-in">
			<router-view ref="routerView"></router-view>
		</transition>
	</div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
	name: "isurveys",
	computed: {
		...mapState("surveys", ["pageTitle", "surveysTitle"]),
		navBarTitle() {
			return this.pageTitle || this.$route.meta.title;
		},
	},
	created() {
    // 设置表单类型	类型:1-调研 2-临床

    const { type } = this.$route.query
    const { titlePrefix } = this.$config[this.$store.getters["user/getAppid"]]

		if (titlePrefix ==="E") {
      this.SET_SURVEYS_TITLE(type == 2 ? "病例秀" : "E洞察")
		} else {
      this.SET_SURVEYS_TITLE(type == 2 ? "i临床" : "i调研")
		}

    this.SET_PAGE_TITLE(this.surveysTitle)
	},
	methods: {
    ...mapMutations('surveys', ['SET_PAGE_TITLE', 'SET_SURVEYS_TITLE']),
		clickLeft() {
			if (this.$route.name === "iSurveysForm") {
				this.$refs.routerView.exitIntercept();
			} else {
				this.$router.go(-1);
			}
		},
	},
};
</script>
<style></style>
